html,
body,
#root {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

body {
  padding: 16px;
}

.App {
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* tabs */
.tab-wrap {
  background-color: #fff;
  width: 220px;
  margin: 0 auto;
}

input[name="tab-items"] {
  display: none;
}

.tab-label {
  width: 50%;
  height: 20px;
  border-bottom: 2px solid #5ab4bd;
  line-height: 20px;
  text-align: center;
  display: block;
  float: left;
  text-align: center;
  cursor: pointer;
}

.tab-wrap input:checked + .tab-label {
  background-color: #5ab4bd;
  color: #fff;
}

.tab-content {
  display: none;
  clear: both;
  overflow: hidden;
}

#style:checked ~ #style-content,
#props:checked ~ #props-content {
  display: block;
}

.tab-wrap td:first-child {
  text-align: right;
  width: 85px;
}
.tab-wrap td input[type="text"] {
  width: 120px;
}
